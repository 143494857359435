import {
  IconBrandDiscordFilled
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="mt-auto w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-5">
        <div>
          <button
            className="flex-none text-xl font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent"
            href="#"
            aria-label="Brand"
          >
            ECLYPSE.CC
          </button>
        </div>

        <ul className="text-center">
          <li className="inline-block relative pe-8 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-3 before:-translate-y-1/2 before:content-['/'] before:text-neutral-600">
            <button
              className="inline-flex gap-x-2 text-sm focus:outline-none text-neutral-500 hover:text-neutral-200 focus:text-neutral-200"
              onClick={() => navigate("/")}
            >
              Home
            </button>
          </li>
          <li className="inline-block relative pe-8 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-3 before:-translate-y-1/2 before:content-['/'] before:text-neutral-600">
            <button
              className="inline-flex gap-x-2 text-sm focus:outline-none text-neutral-500 hover:text-neutral-200 focus:text-neutral-200"
              onClick={() => navigate("/store")}
            >
              Store
            </button>
          </li>
          <li className="inline-block relative pe-8 last:pe-0 last-of-type:before:hidden before:absolute before:top-1/2 before:end-3 before:-translate-y-1/2 before:content-['/'] before:text-neutral-600">
            <button
              className="inline-flex gap-x-2 text-sm focus:outline-none text-neutral-500 hover:text-neutral-200 focus:text-neutral-200"
              onClick={() => navigate("/subscriptions")}
            >
              Dashboard
            </button>
          </li>
        </ul>
        <div className="md:text-end space-x-2">
          <a href="https://www.elitepvpers.com" target="_blank" rel="noreferrer" className="inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-transparent  text-white focus:outline-none disabled:opacity-50 disabled:pointer-events-none">
            <img src="/assets/elitepvpers.png" width={20} alt="elitepvpers"></img>
          </a>
          <a href="https://discord.gg/u8bYUXG4Pz" target="_blank" className="size-8 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-full border border-transparent focus:outline-none disabled:opacity-50 disabled:pointer-events-none text-neutral-400 hover:bg-neutral-700 focus:bg-neutral-700" rel="noreferrer">
            <IconBrandDiscordFilled size={18} className="shrink-0" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
